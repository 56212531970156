<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1 max-w-1400">
        <!-- <img :src="productImg1" :alt="altText" class="imgItem" /> -->
      </div>
    </div>
    <div class="imgBox2 min-w-1400">
      <div class="img2 max-w-1400">
        <img :src="productImg2" :alt="altText" class="imgItem2" />
      </div>
    </div>
  </div>
</template>

<script>
import productImg2 from "@/page/nathcopay/components/img/2_1.png";
export default {
  data() {
    return {
      productImg2: productImg2,
      altText: "图片",
    };
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: #f3f3f3;
}
.imgBox1 {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  max-width: 1620px;
  width: 100%;
  height: 250px;
  background-color: #2E7BF7  ;
}
.imgBox2 {
  display: flex;
  justify-content: center;
  max-width: 1400px;
  padding-top: 40px;
  padding-bottom: 20px;
  height: 2680px;
  background-color: #ffffff;
  border-radius: 32px;
  margin-top: -130px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
}
img {
  display: block;
}
.img1 {
  overflow: hidden;
  width: 100%;
}
.img2 {
  overflow: hidden;
}
.imgItem1 {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}
.imgItem2 {
  max-width: 100%;
}
</style>
